.global-header{
    font-size: 20px;
    line-height: 1em;
    padding: 6px;
    position: fixed;
    top: 0;left: 0;
    // width: 100%;
    height: 30px;
    overflow: hidden;
    letter-spacing: 0;
    font-feature-settings: "palt";
    z-index: 10;
    h1 a{
        mix-blend-mode: lighten;
    }
}

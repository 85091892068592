.global-navigation{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 20;
    top: 0;left: 0;
    width: 100%;
    height: 100%;
    background-color: #141915;
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s;
    .js-menu-open &{
        opacity: 1;
        pointer-events: auto;
    }
    &__list{
        text-align: left;
        &--item{
            font-size: 1.5em;
            line-height: 1.5;
            a{
                display: inline-block;
                padding: 10px;
            }
            small{
                font-size: 10px;
                font-family: dnp-shuei-gothic-gin-std, sans-serif;
                font-weight: 500;
                font-style: normal;
                letter-spacing: 0;
                display: block;
            }
        }
    }
    &__button{
        display: block;
        position: fixed;
        z-index: 30;
        right: 0;
        bottom: 20px;
        width: 40px;
        height: 40px;
        span{
            background-color: #fff;
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            transition: transform .5s;
            &:nth-child(1){
                top:20%;
                .js-menu-open &{transform:translateY(12px) rotate(45deg);}
            }
            &:nth-child(2){
                top:50%;
                .js-menu-open &{transform: rotateY(90deg);}
            }
            &:nth-child(3){
                bottom:20%;
                .js-menu-open &{transform:translateY(-11px) rotate(-45deg);}
            }
        }
    }
}

@charset "UTF-8";
@import "base";

//プラグイン
@import "swiper.min";

//パーツ
@import "sns-icons";

//ブロック
@import "global-header";
@import "global-footer";
@import "global-navigation";
@import "fixed-firstview";
@import "slide-block";
@import "section";
@import "article";

//ページレイアウト
@import "single";
@import "page";
@import "archive";


.font-loader{
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90;
    background: linear-gradient(to right, rgba(20,25,21,1) 0%,rgba(20,25,21,1) 25%,rgba(20,25,21,0) 75%,rgba(20,25,21,0) 100%);
    background-size: 400%;
    background-position: 0% 0%;
    opacity: 1;
    transition: background-position 1s,opacity 500ms 500ms;
    .wf-active &{
        opacity: 0;
        background-position: 100% 0%;
    }
}

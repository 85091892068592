.slide-block{
    display: block;
    position: relative;
    z-index: 0;
    overflow: hidden;
    background-color: #141915;
    background-image: url(../img/tail-spin.svg);
    background-position: center 40%;
    backface-visibility: hidden;
    &:before{
        content: "";
        display: block;
        width: 100%;
        padding-top: 160%;
    }
    &__img{
        position: absolute;
        top: 0;left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
        backface-visibility: hidden;
    }
    &__info{
        position: absolute;
        left: 0;bottom: 0;
        width: 100%;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.9) 100%);
        padding: 5em 1em 0 0;
        &--title{
            font-family: dnp-shuei-gothic-gin-std, sans-serif;
            font-weight: 500;
            font-style: normal;
            color: #141915;
            font-feature-settings: "palt";
            letter-spacing: 0;
            line-height: 1.8;
            padding: 0 .5em;
            span{
                background-color: #fff;
                padding: .3em 0;
                white-space:pre-wrap;
                box-shadow: .5em 0 0 #fff, -.5em 0 0 #fff;
            }
        }
        &--date{
            font-size: 10px;
            padding-left: .75em;
        }
    }
    &__andmore{
        background-color: transparent;
        background-image: none;
        border: 1px solid rgba(255,255,255,.5);
        h6,
        span{
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 14px;
            // margin-top: -.5em;
        }
        h6{
            font-size: 18px;
            margin-top: -2em;
        }
    }
}
.slide-fv-block{
    display: block;
    position: relative;
    z-index: 0;
    overflow: hidden;
    &:before{
        content: "";
        display: block;
        width: 100%;
        padding-top: calc(100vh - 30px);
    }
    &__info{
        position: absolute;
        left: 0;bottom: 10%;
        width: 100%;
        box-sizing: border-box;
        padding-right: 20vw;
        &--category{
            font-size: 16px;
            padding-left: 10px;
        }
        &--title{
            font-size: 1.5em;
            font-family: dnp-shuei-gothic-gin-std, sans-serif;
            font-weight: 500;
            font-style: normal;
            color: #141915;
            font-feature-settings: "palt";
            letter-spacing: 0;
            line-height: 1.8;
            padding: 0 .5em;
            span{
                background-color: #fff;
                padding: .3em 0;
                white-space:pre-wrap;
                box-shadow: .5em 0 0 #fff, -.5em 0 0 #fff;
            }
        }
        &--date{
            font-size: 10px;
            padding-left: 10px;
        }
    }
    &__container{
        .swiper-pagination-bullets{
            bottom: 5%;
        }
        .swiper-pagination-bullet{
            background-color: #fff;
            width: 4px;
            height: 4px;
        }
    }
}

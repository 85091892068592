.archive{
    &__fv{
        padding: 10em 20px 5em;
        text-align: center;
        &__title{
            font-size: 2em;
            line-height: 1.5;
        }
    }
    &__wrapper{
        display: flex;
        flex-wrap: wrap;
        padding:0 10px;
        max-width: 1440px;
        margin:0 auto 6em;
    }
    &__item{
        padding: 5px;
        width: 50%;
        @media(min-width: 550px){ width: calc(100% / 3); }
        @media(min-width: 800px){ width: calc(100% / 4); }
        @media(min-width: 1000px){ width: calc(100% / 5); }

    }
    &__item-block{
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 0;
        overflow: hidden;
        &:before{
            content: "";
            display: block;
            width: 100%;
            padding-top: 160%;
        }
        &__img{
            position: absolute;
            top: 0;left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            object-fit: cover;
        }
        &__info{
            position: absolute;
            left: 0;bottom: 0;
            width: 100%;
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.9) 100%);
            padding: 5em 1em 0 0;
            &--title{
                font-size: .9em;
                font-family: dnp-shuei-gothic-gin-std, sans-serif;
                font-weight: 500;
                font-style: normal;
                color: #141915;
                font-feature-settings: "palt";
                letter-spacing: 0;
                line-height: 1.8;
                padding: 0 .5em;
                span{
                    background-color: #fff;
                    padding: .3em 0;
                    white-space:pre-wrap;
                    box-shadow: .5em 0 0 #fff, -.5em 0 0 #fff;
                }
            }
            &--date{
                font-size: 10px;
                padding-left: .75em;
            }
        }

    }
}

.section{
    padding: 0 0 3em;
    position: relative;
    overflow: hidden;
    &__title{
        text-align: center;
        font-size: 1.5em;
        // letter-spacing: 0;
        font-feature-settings: "palt";
        // mix-blend-mode: exclusion;
        &.vertical{
            position: absolute;
            top: 0;left: 0;
            z-index: 2;
            line-height: 3;
            writing-mode: vertical-rl;
        }
    }
}

.sns-icons{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0;
    a{
        display: block;
        padding: 10px 15px;
    }
}

.fixed-firstview{
    position: fixed;
    bottom: 0;left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    height: calc(var(--vh, 1vh) * 120);
    backface-visibility: hidden;
    // overflow: hidden;
    .swiper-container{
        height: 100%;
        height: calc(var(--vh, 1vh) * 120);
    }
    img{
        width: 100%;
        height: 100%;
        height: calc(var(--vh, 1vh) * 120);
        object-fit: cover;
        position: absolute;
        bottom: 0;left: 0;
        backface-visibility: hidden;
    }
    &__contents{
        // height: calc(100% - 30px);
        height: calc(var(--vh, 1vh) * 100);
        // height: calc(var(--vh, 1vh) * 100 - 30px);
        position: relative;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 30%,rgba(0,0,0,0.6) 100%);
    }
}

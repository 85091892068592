.page{
    &__fv{
        padding: 10em 20px 5em;
        text-align: center;
        &__title{
            font-size: 2em;
            line-height: 1.5;
        }
        &__subtitle{
            font-size: 10px;
            font-family: dnp-shuei-gothic-gin-std, sans-serif;
            font-weight: 500;
            font-style: normal;
            letter-spacing: 0;
        }
    }
    &__article{}
}

@charset "UTF-8";

::selection{
    background: #ff01d4;
    color: #fbff01;
}

*,
*:before,
*:after{
    margin: 0;
    padding: 0;
    list-style: none;
    background-repeat: no-repeat;
    box-sizing:inherit;
    vertical-align:inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: normal;
    line-height: inherit;
    color:inherit;
}
html, body {
    height: 100%;
}
html{
    // font-family: dnp-shuei-gothic-gin-std, sans-serif;
    // font-weight: 500;
    // font-style: normal;

    font-family: minion-3-display, tbnpmincho-std, serif;
    font-weight: 300;
    font-style: normal;
    font-size: 13px;
    line-height: 2.4;
    letter-spacing:0.1em;
    background-color: #141915;
    color: #fff;
    // font-feature-settings: "palt";
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
    box-sizing:border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
a,a img,button{
    outline: none;
    border: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
button{
    background-color: transparent;
    padding: 0;
    appearance: none;
}
input, textarea{
    padding: 0;
    border: none;
    border-radius: 0;
    outline: none;
    background: none;
}
input[type="text"]:focus, textarea:focus{
    outline: 0;
}
input[type="submit"], input[type="button"]{
    border-radius: 0;
    -webkit-box-sizing: content-box;
    -webkit-appearance: button;
    appearance: button;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
}

img{
    vertical-align: bottom;
    max-width:100%;
    height: auto;
}
a{
    text-decoration: none;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
}
a:hover,button:hover{
    outline: none;
}
em, i{
    font-style: normal;
}
a:disabled,button:disabled,input:disabled{pointer-events:none;}
